// IntroPage.js
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import './IntroPage.css';
import logo from './logo.png';
import viewIcon from './view-icon.png';
import { useNavigate } from 'react-router-dom';

const IntroPage = () => {
    const navigate = useNavigate();

    const handleRecruiterClick = () => {
        navigate('/recruiter');
    };
    
    const handleFriendClick = () => {
        navigate('/friend');
    };

    // Google Analytics
    useEffect(() => {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = "https://www.googletagmanager.com/gtag/js?id=G-BKK7KS6N4C";
      document.head.appendChild(script1);
  
      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-BKK7KS6N4C');
      `;
      document.head.appendChild(script2);
    }, []);
    
  return (
    <div className="intro-page">
      {/* Logo and Name Section */}
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="logo-text">meet Amaj</h1>
        <a 
          href="https://marketplace.atlassian.com/apps/1235100/story-point-automator?tab=overview&hosting=cloud&ref=producthunt" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="jira-link"
        >
         New App! - Jira Story Point Automator
        </a>
      </div>

      {/* Main Content */}
      <div className="main-content">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.3 }}
          className="intro-heading"
        >
          who is Amaj?
        </motion.h1>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.0 }}
          className="intro-subheading"
        >
          to better answer that question ...
        </motion.p>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 2.0 }}
          className="intro-subheading2"
        >
          who are you?
        </motion.p>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 2.5 }}
          className="intro-buttons"
        >
          <button className="intro-button" onClick={handleRecruiterClick}>recruiter / work</button>
          <button className="intro-button" onClick={handleFriendClick}>friend / family</button>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 3.0 }}
          className="resume-view"
        >
          <a href="/resume.pdf" target="_blank" className="view-link">
            <img src={viewIcon} alt="View Icon" className="view-icon" />
            view his resume
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default IntroPage;
